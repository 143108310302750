<template>
   <component
      :is="as"
      v-bind="attrs"
      v-on="$listeners"
      :class="{
         'd-inline': inline,
         'd-inline-block': inlineBlock,
         'float-end': floatEnd,
         'float-start': floatStart,
         'text-center': center,
         'fs-large': fsLarge,
         'fs-xlarge': fsXlarge,
         'container-fluid': fluid,
         'fw-500': fwBold,
      }"
      :style="{ color: Color }"
      @mouseover="$emit('mouseover')"
   >
      <slot />
   </component>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'AmcText',
   props: {
      as: {
         type: [String, Object],
         default: 'div',
      },
      inline: {
         type: Boolean,
         default: false,
      },
      inlineBlock: {
         type: Boolean,
         default: false,
      },
      floatEnd: {
         type: Boolean,
         default: false,
      },
      floatStart: {
         type: Boolean,
         default: false,
      },
      center: {
         type: Boolean,
         default: false,
      },
      fsLarge: {
         type: Boolean,
         default: false,
      },
      fsXlarge: {
         type: Boolean,
         default: false,
      },
      fluid: {
         type: Boolean,
         default: false,
      },
      Color: {
         type: [Number, String],
         default: '',
      },
      fwBold: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>

<style lang="scss" scoped>
.fs-large {
   font-size: 3rem !important;
}

.fs-xlarge {
   font-size: 3.5rem !important;
}

.amc-text {
}
</style>
