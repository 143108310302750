var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.as,_vm._g(_vm._b({tag:"component",class:{
      'd-inline': _vm.inline,
      'd-inline-block': _vm.inlineBlock,
      'float-end': _vm.floatEnd,
      'float-start': _vm.floatStart,
      'text-center': _vm.center,
      'fs-large': _vm.fsLarge,
      'fs-xlarge': _vm.fsXlarge,
      'container-fluid': _vm.fluid,
      'fw-500': _vm.fwBold,
   },style:({ color: _vm.Color }),on:{"mouseover":function($event){return _vm.$emit('mouseover')}}},'component',_vm.attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }